import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Layout, Menu, Spin } from 'antd';
import classes from './homePage.module.css';
import { authorRouter } from '../../router/AuthRouter';

// import SwitchLanguage from '../../components/common/SwitchLanguage/switchLanguage';
import HomeUser from '../../components/common/HomeUser/homeUser';
import MerchantProvider from '../../MerchantProvider';
import eCom365Logo from '../../static/e-Com365-logo.svg';

import {
    IconMenuUserGreySvg,
    IconMenuUserBlueSvg,
    IconMenuDashboardGraySvg,
    IconMenuDashboardBlueSvg,
    IconMenuCustomerGreySvg,
    IconMenuCustomerBlueSvg,
    IconMenuCampaignGreySvg,
    IconMenuCampaignBlueSvg,
    IconMenuReportGreySvg,
    IconMenuReportBlueSvg,
    IconMenuMerchantUserGreySvg,
    IconMenuMerchantUserBlueSvg,
    IconMenuOrderGreySvg,
    IconMenuOrderBlueSvg,
    IconMenuPromotionGreySvg,
    IconMenuPromotionBlueSvg,
    IconMenuSettingsGreySvg,
    IconMenuSettingsBlueSvg,
    IconMenuRewardGreySvg,
    IconMenuRewardBlueSvg,
    IconMenuProductsGreySvg,
    IconMenuProductsBlueSvg,
    IconMenuAnalyticsGreySvg,
    IconMenuAnalyticsBlueSvg,
    IconMenuMarketplacesGreySvg,
    IconMenuMarketplacesBlueSvg,
    IconMenuVendorGreySvg,
    IconMenuVendorBlueSvg,
    IconMenuWarrantyBlueSvg,
    IconMenuWarrantyGreySvg,
    IconOnlineServiceBlueSvg,
    IconOnlineServiceGreySvg
} from '../../components/common/IconsSvg/iconsSvg';
import { useSelector, useDispatch } from 'react-redux';

const { Header, Sider, Content } = Layout;

const Homepage = props => {

    const { formatMessage } = useIntl();
    const location = useLocation();
    const navigate = useNavigate();
    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([location.pathname]);
    const [defaultOpenKeys, setDefaultOpenKeys] = useState([]);
    const [nowKey, setNowKey] = useState(location.pathname);

    const { userInfo, menuList, subscribedServiceList, isMerchantLoginCompleted } = useSelector((state) => state.user)

    const MenuData = useMemo(() => {


        function checkPermission(menuItem, isTop) {

            if (isTop) {

                if (menuItem.children && menuItem.children.length) {
                    const menuChildMatch = menuItem.children.find(menuChild => {
                        // 是否开启
                        if (menuChild.status == '1') {
                            // 是否所有人可查看
                            if (menuChild.serviceCode == "-") {
                                return true
                            } else {
                                const matchSubscribedItem = subscribedServiceList.find(item => {
                                    return menuChild.serviceCode == item.serviceCode
                                })
                                // merchant 是否订阅
                                if (matchSubscribedItem) {
                                    // user是否有权限
                                    if (userInfo && userInfo.allowService && (userInfo.allowService.includes('All') || userInfo.allowService.includes(matchSubscribedItem.serviceCode))) {
                                        return true
                                    } else {
                                        return false
                                    }

                                } else {
                                    return false
                                }
                            }
                        } else {
                            return false

                        }

                    })

                    // 子菜单有可以看的
                    if (menuChildMatch) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    // 是否开启
                    if (menuItem.status == '1') {
                        // 是否所有人可查看
                        if (menuItem.code == "permission/marketplaces") {
                            if (userInfo && userInfo.hasReleatedMarkets) {
                                return true
                            } else {
                                return false
                            }
                        } else if (menuItem.serviceCode == "-") {
                            return true
                        } else {

                            const matchSubscribedItem = subscribedServiceList.find(item => {
                                return menuItem.serviceCode == item.serviceCode
                            })
                            // merchant 是否订阅
                            if (matchSubscribedItem) {
                                // user是否有权限
                                if (userInfo && userInfo.allowService && (userInfo.allowService.includes('All') || userInfo.allowService.includes(matchSubscribedItem.serviceCode))) {
                                    return true
                                } else {
                                    return false
                                }

                            } else {
                                return false
                            }
                        }

                    } else {
                        return false
                    }
                }





            } else {
                // 是否开启
                if (menuItem.status == '1') {
                    // 是否所有人可查看
                    if (menuItem.serviceCode == "-") {
                        return true
                    } else {

                        const matchSubscribedItem = subscribedServiceList.find(item => {
                            return menuItem.serviceCode == item.serviceCode
                        })
                        // merchant 是否订阅
                        if (matchSubscribedItem) {
                            // user是否有权限
                            if (userInfo && userInfo.allowService && (userInfo.allowService.includes('All') || userInfo.allowService.includes(matchSubscribedItem.serviceCode))) {
                                return true
                            } else {
                                return false
                            }

                        } else {
                            return false
                        }
                    }

                } else {
                    return false
                }
            }



        }

        function getMenuIcon(code, status) {

            let icon = '';

            switch (code) {
                case 'permission/dashboard':
                    icon = status ? IconMenuDashboardBlueSvg : IconMenuDashboardGraySvg;
                    break;
                case 'permission/customer':
                    icon = status ? IconMenuUserBlueSvg : IconMenuUserGreySvg;
                    break;
                case 'permission/campaign':
                    icon = status ? IconMenuCampaignBlueSvg : IconMenuCampaignGreySvg;
                    break;
                case 'permission/merchant_users':
                    icon = status ? IconMenuMerchantUserBlueSvg : IconMenuMerchantUserGreySvg;
                    break;
                case 'permission/merchant_vendor':
                    icon = status ? IconMenuVendorBlueSvg : IconMenuVendorGreySvg;
                    break;
                case 'permission/marketplaces':
                    icon = status ? IconMenuMarketplacesBlueSvg : IconMenuMarketplacesGreySvg;
                    break;
                case 'permission/cms':
                    icon = status ? IconMenuReportBlueSvg : IconMenuReportGreySvg;
                    break;
                case 'permission/reports':
                    icon = status ? IconMenuReportBlueSvg : IconMenuReportGreySvg;
                    break;

                case 'permission/order':
                    icon = status ? IconMenuOrderBlueSvg : IconMenuOrderGreySvg;
                    break;

                case 'permission/products':
                    icon = status ? IconMenuProductsBlueSvg : IconMenuProductsGreySvg
                    break;
                case 'permission/productmarketplace':
                    icon = status ? IconMenuMarketplacesBlueSvg : IconMenuMarketplacesGreySvg;
                    break;
                case 'permission/promotion':
                    icon = status ? IconMenuPromotionBlueSvg : IconMenuPromotionGreySvg;
                    break;

                case 'permission/settings':
                    icon = status ? IconMenuSettingsBlueSvg : IconMenuSettingsGreySvg;
                    break;

                case 'permission/Settings':
                    icon = status ? IconMenuSettingsBlueSvg : IconMenuSettingsGreySvg;
                    break;

                case 'permission/reward_points':
                    icon = status ? IconMenuRewardBlueSvg : IconMenuRewardGreySvg;
                    break;
                case 'permission/analytics':
                    icon = status ? IconMenuAnalyticsBlueSvg : IconMenuAnalyticsGreySvg;
                    break;
                case 'permission/warranty':
                    icon = status ? IconMenuWarrantyBlueSvg : IconMenuWarrantyGreySvg;
                    break;
                case 'permission/event':
                    icon = status ? IconMenuWarrantyBlueSvg : IconMenuWarrantyGreySvg;
                    break;
                case 'permission/chat':
                    icon = status ? IconOnlineServiceBlueSvg : IconOnlineServiceGreySvg;
                    break;
                case 'permission/b2b_sales_center':
                    icon = status ? IconMenuUserBlueSvg : IconMenuUserGreySvg;
                    break;
                default:
                    break;
            }

            return icon;

        }

        let data = [];

        function getChildMenu(childMenu) {
            if (!childMenu || !childMenu.length) return null;

            let data2 = [];
            childMenu.forEach(item => {
                if (item.status == "1") {
                    data2.push({
                        key: item.frontUrl && process.env.NODE_ENV == "production" ? `${item.frontUrl}${item.path}` : `${window.location.origin}${item.path}`,
                        icon: null,
                        children: getChildMenu(item.children),
                        //label: <a onClick={()=>navigate(item.path)}>{item.name}</a>,
                        label: item.name
                    })
                }

            })

            return data2
        }

        menuList.forEach(item => {

            if (item.status == "1") {
                const key = item.frontUrl && process.env.NODE_ENV == "production" ? `${item.path ? item.frontUrl : ''}${item.path ? item.path : item.code}` : `${item.path ? window.location.origin : ''}${item.path ? item.path : item.code}`
                data.push({
                    key: key,
                    icon: getMenuIcon(item.code, nowKey == key),
                    children: getChildMenu(item.children),
                    label: item.name,
                })
            }

        })
        return data
    }, [nowKey, menuList, subscribedServiceList, userInfo])

    const handleMenuSelected = useCallback((val) => {
        // console.log(val);
        if (val && val.key) {
            setNowKey(val.key);
        }
    }, []);

    const handleMenuOpen = useCallback((openKeys) => {
        // console.log(openKeys);
        setDefaultOpenKeys(openKeys)
    }, []);

    const handleClickMenu = useCallback(({ item, key }) => {

        const url = new URL(key);
        const origin = window.location.origin;
        if (!process.env.REACT_APP_BASE_NAME || (process.env.REACT_APP_BASE_NAME && key.indexOf(origin + process.env.REACT_APP_BASE_NAME + '/index') > -1) || url.pathname.includes(process.env.REACT_APP_BASE_NAME + '/index')) {
            const currentPath = url.pathname.replace(process.env.REACT_APP_BASE_NAME, '');
            const relativePath = currentPath + url.search + url.hash;
            navigate(`${relativePath}`);
        } else {
            window.location.href = `${key}`;
        }
    }, [navigate, userInfo]);

    useEffect(() => {
        let pathArr = [];
        MenuData.forEach((item) => {
            if (item.key.includes(location.pathname)) {
                pathArr.push(item.key)
            }

            if (item.children) {
                item.children.forEach((child) => {
                    const rep = new RegExp(`${location.pathname}$`);
                    if (rep.test(child.key)) {
                        pathArr.push(child.key)
                    } else if (new RegExp(child.key + '/id/').test(window.location.origin + location.pathname) || new RegExp(child.key + '/create').test(window.location.origin + location.pathname)) {
                        pathArr.push(child.key)
                    }
                    if (child.children) {
                        child.children.forEach(childChild => {
                            if (rep.test(childChild.key)) {
                                pathArr.push(childChild.key);
                                pathArr.push(child.key);
                                pathArr.push(item.key);
                            } else if (new RegExp(childChild.key + '/id/').test(window.location.origin + location.pathname) || new RegExp(childChild.key + '/create').test(window.location.origin + location.pathname)) {
                                pathArr.push(childChild.key);
                                pathArr.push(child.key);
                                pathArr.push(item.key);
                            }
                        });
                    }
                })

                let manageEshopPath;
                const matchild = item.children.find((child) => {
                    if (child.children) {
                        let resFlag = false;
                        child.children.find(childChild => {
                            let res = childChild.key.includes(location.pathname) ||
                                (window.location.origin + location.pathname).includes(childChild.key + '/id/') ||
                                (window.location.origin + location.pathname).includes(childChild.key + '/view/') ||
                                (window.location.origin + location.pathname).includes(childChild.key + '/code/') ||
                                (window.location.origin + location.pathname).includes(childChild.key + '/create');
                            if (res) {
                                pathArr.push(childChild.key);
                                pathArr.push(child.key);
                            }
                            if (res) {
                                resFlag = res;
                            }

                            return;
                        });

                        return resFlag;
                    }
                    if (location.pathname.includes('/index/products/manage-eshop') && child.key.includes('/index/products/manage-eshop')) {
                        //manageEshopPath = child.key;
                        pathArr.push(child.key);
                        return true;
                    }
                    let res = child.key.includes(location.pathname) ||
                        (window.location.origin + location.pathname).includes(child.key + '/id/') ||
                        (window.location.origin + location.pathname).includes(child.key + '/view/') ||
                        (window.location.origin + location.pathname).includes(child.key + '/code/') ||
                        (window.location.origin + location.pathname).includes(child.key + '/create');
                    if (res) {
                        pathArr.push(child.key);
                    }
                    return res;
                })

                if (matchild) {
                    pathArr.push(item.key);
                }

                // if (manageEshopPath) {
                //     pathArr.push(manageEshopPath);
                // }
            }

            setDefaultOpenKeys(pathArr);
            setDefaultSelectedKeys(pathArr);
            setNowKey(pathArr[0]);
        })

    }, [location.pathname, MenuData]);

    const menuNode = useMemo(() => {
        return <Menu
            // 根据url地址实现选中高亮
            mode='inline'
            inlineIndent={12}
            selectedKeys={defaultSelectedKeys}
            openKeys={defaultOpenKeys}
            items={MenuData}
            onSelect={(val) => { handleMenuSelected(val) }}
            onClick={handleClickMenu}
            onOpenChange={(val) => { handleMenuOpen(val) }}
        >
            {/* {
                navItems.map(menu=>{
                    return <Menu.Item key={menu.key}>
                        {nowKey === menu.key ? menu.active_icon : menu.icon}
                        <Link to={menu.path}>{menu.label}</Link>
                    </Menu.Item>
                })
            } */}
        </Menu>
    }, [defaultOpenKeys, defaultSelectedKeys, MenuData, handleClickMenu, handleMenuSelected])


    return (
        <Layout className={classes.root}>
            <Header>

                <div className={classes.header_title}>
                    <img src={eCom365Logo} alt="e-Com365" />
                    <FormattedMessage id="global.webName" defaultMessage={"e-Com365  Go CRM Micro-service"} />
                </div>
                <div className={classes.header_right}>
                    {/* <SwitchLanguage />
                    <div className={classes.headerRightBox}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.87378 4.99866C1.87378 4.3083 2.43342 3.74866 3.12378 3.74866H16.8738C17.5641 3.74866 18.1238 4.3083 18.1238 4.99866V14.9987C18.1238 15.689 17.5641 16.2487 16.8738 16.2487H3.12378C2.43342 16.2487 1.87378 15.689 1.87378 14.9987V4.99866ZM16.372 4.99866H3.62551L9.99878 9.2475L16.372 4.99866ZM3.12378 6.16648L3.12378 14.9987H16.8738V6.16648L9.99878 10.7498L3.12378 6.16648Z" fill="white" fillOpacity="0.9" />
                        </svg>
                        <div className={classes.messageNumBox}>
                            2
                        </div>
                    </div>
                    <div className={classes.headerRightBox}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.24255 14.5C9.24255 14.0858 9.57834 13.75 9.99255 13.75C10.4068 13.75 10.7426 14.0858 10.7426 14.5C10.7426 14.9142 10.4068 15.25 9.99255 15.25C9.57834 15.25 9.24255 14.9142 9.24255 14.5Z" fill="white" fillOpacity="0.9" />
                            <path d="M9.99261 5.00763C8.32056 5.00763 6.96136 6.36682 6.96136 8.03888H8.21136C8.21136 7.05718 9.01092 6.25763 9.99261 6.25763C10.9743 6.25763 11.7739 7.05718 11.7739 8.03888C11.7739 8.71106 11.2183 9.3851 10.4931 9.67971L10.4899 9.68101C9.82793 9.95419 9.36767 10.6052 9.36767 11.3656V12.5H10.6177V11.3656C10.6177 11.1228 10.7618 10.9216 10.9655 10.837C11.9996 10.4161 13.0239 9.37337 13.0239 8.03888C13.0239 6.36682 11.6647 5.00763 9.99261 5.00763Z" fill="white" fillOpacity="0.9" />
                            <path d="M18.7427 10C18.7427 5.16751 14.8252 1.25 9.99268 1.25C5.16019 1.25 1.24268 5.16751 1.24268 10C1.24267 14.8325 5.16018 18.75 9.99267 18.75C14.8252 18.75 18.7427 14.8325 18.7427 10ZM17.4927 10C17.4927 14.1421 14.1348 17.5 9.99267 17.5C5.85054 17.5 2.49267 14.1421 2.49268 10C2.49268 5.85786 5.85054 2.5 9.99268 2.5C14.1348 2.5 17.4927 5.85787 17.4927 10Z" fill="white" fillOpacity="0.9" />
                        </svg>
                    </div> */}
                    <HomeUser />

                </div>
            </Header>
            <Layout>
                <Sider>
                    {menuNode}
                </Sider>
                <Content>
                    {isMerchantLoginCompleted ? subscribedServiceList && subscribedServiceList.length ? <Outlet /> : <div>
                        <FormattedMessage id="global.noSubscribes" defaultMessage={"You do not have a subscription service, please contact the merchant."} />
                    </div> : <Spin spinning={true} />}
                    <div className={classes.footer}>
                        Copyright @ 2025 BCNetcom. All Rights Reserved
                    </div>
                    <MerchantProvider />
                </Content>
            </Layout>
        </Layout>
    )
}

export default Homepage;