import { useEffect, useMemo } from "react";

const RouterTitle = props => {

    const { title, children } = props;

    // useEffect(() => {
    document.title = title;
    // }, [title]);

    return children
}

export default RouterTitle;