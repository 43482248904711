import { zh_CN } from './zh_CN';
import { zh_HK } from './zh_HK';
import { en_US } from './en_US';
// servcie
import { en_US_service } from './en_US_service';
import { zh_CN_service } from './zh_CN_service';
import { zh_HK_service } from './zh_HK_service';

function loadLocale(lang) {
    let locale = null;
    let message = null;
    switch (lang) {
        case 'en':
            locale = 'en-US';
            message = Object.assign(en_US, en_US_service);
            break;
        case 'cn':
            locale = 'zh-CN';
            message = Object.assign(zh_CN, zh_CN_service);
            break;
        case 'hk':
            locale = 'zh-HK';
            message = Object.assign(zh_HK, zh_HK_service);
            break;
        default:
            locale = 'en-US';
            message = Object.assign(en_US, en_US_service);
            break;
    }
    return { locale, message };
}
export { loadLocale };