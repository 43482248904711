
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { useIntl } from 'react-intl';
// import RouterAuth from '../components/common/RouterAuth/routerAuth';
import RouterTitle from '../components/common/RouterTitle/routerTitle';
import { authorRouter } from './AuthRouter'
import PageNotFound from "../components/common/PageNotFound/pageNotFound";

const Routers = props => {

  const { formatMessage } = useIntl();

  const routes = authorRouter.map((item, index) => {

    if (item.children?.length) {
      return {
        path: item.path,
        element: <RouterTitle
          title={formatMessage({ id: item.messageId, defaultMessage: item.defaultMessage })}
        > {item.element}</RouterTitle>,
        auth: item.auth,
        children: item.children.map(child => {
          return {
            path: child.path,
            element:
              //  child.element
              <RouterTitle
                title={formatMessage({ id: child.messageId, defaultMessage: child.defaultMessage })}
                permissionCode={child.permissionCode}
              >
                {child.element}
              </RouterTitle>,
          }
        })
      }
    }
    else {
      return {
        path: item.path,
        element: <RouterTitle
          title={formatMessage({ id: item.messageId, defaultMessage: item.defaultMessage })}
        >
          {item.element}
        </RouterTitle>,
        auth: item.auth,
        children: []
      }
    }
  })

  routes && routes[0] && routes[0].children && routes[0].children.push({
    path: '*',
    element: <PageNotFound />
  });


  const elements = useRoutes(routes)

  return elements
}

export default Routers;