import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Layout, Menu, Spin } from 'antd';
import classes from './profile.module.css';
import { authorRouter } from '../../router/AuthRouter';

// import SwitchLanguage from '../../components/common/SwitchLanguage/switchLanguage'
import HomeUser from '../../components/common/HomeUser/homeUser'
import UserProvider from '../../UserProvider';
import eCom365Logo from '../../static/e-Com365-logo.svg';

import {
    IconMenuUserGreySvg,
    IconMenuUserBlueSvg,
    IconMenuSettingsGreySvg,
    IconMenuSettingsBlueSvg,
    IconMenuVendorGreySvg,
    IconMenuVendorBlueSvg,

} from '../../components/common/IconsSvg/iconsSvg';

const { Header, Sider, Content } = Layout;

const Profile = props => {

    const { formatMessage } = useIntl();
    const location = useLocation();
    const navigate = useNavigate();
    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([location.pathname]);
    const [defaultOpenKeys, setDefaultOpenKeys] = useState([]);
    const [nowKey, setNowKey] = useState(location.pathname);

    const navItems = [
        {
            key: '/profile/account',
            icon: IconMenuUserGreySvg,
            active_icon: IconMenuUserBlueSvg,
            label: formatMessage({
                id: "homeMenu.merchantAccount",
                defaultMessage: "Merchant Account"
            }),
            path: '/profile/account'
        },
        {
            key: '/profile/my-profile',
            icon: IconMenuVendorGreySvg,
            active_icon: IconMenuVendorBlueSvg,
            label: formatMessage({
                id: "homeMenu.myProfile",
                defaultMessage: "My Profile"
            }),
            path: '/profile/my-profile'
        },
        {
            key: '/profile/security',
            icon: IconMenuSettingsGreySvg,
            active_icon: IconMenuSettingsBlueSvg,
            label: formatMessage({
                id: "homeMenu.security",
                defaultMessage: "Security"
            }),
            path: '/profile/security'
        },



    ];

    const MenuData = useMemo(() => {
        let data = [];
        function getChildMenu(childMenu) {
            if (!childMenu) return null;

            let data2 = [];
            childMenu.forEach(item => {
                data2.push({
                    key: item.key,
                    icon: null,
                    children: null,
                    label: item.label,
                })
            })
            return data2
        }
        navItems.forEach(item => {
            data.push({
                key: item.key,
                icon: nowKey === item.key ? item.active_icon : item.icon,
                children: getChildMenu(item.children),
                label: item.label,
            })
        })
        return data
    }, [nowKey])


    const handleMenuSelected = useCallback((val) => {
        if (val && val.key) {
            setNowKey(val.key);
        }
    }, []);

    const handleMenuOpen = useCallback((openKeys) => {
        setDefaultOpenKeys(openKeys)
    }, []);

    const handleClickMenu = useCallback((e) => {
        if (e.key.includes('childrenMenu')) {
            return false;
        }
        else {
            navigate(e.key);
        }
    }, [navigate]);
    useEffect(() => {

        setDefaultSelectedKeys([location.pathname]);
        setDefaultOpenKeys([location.pathname]);
        setNowKey(location.pathname)
    }, [location.pathname]);

    const menuNode = useMemo(() => {
        return <Menu
            // 根据url地址实现选中高亮
            mode='inline'
            inlineIndent={12}
            selectedKeys={defaultSelectedKeys}
            openKeys={defaultOpenKeys}
            items={MenuData}
            onSelect={(val) => { handleMenuSelected(val) }}
            onClick={handleClickMenu}
            onOpenChange={(val) => { handleMenuOpen(val) }}
        >

        </Menu>
    }, [defaultOpenKeys, defaultSelectedKeys, MenuData, handleClickMenu, handleMenuSelected])


    return (
        <Layout className={classes.root}>
            <Header>
                <div className={classes.header_title}>
                    <img src={eCom365Logo} alt="e-Com365" />
                    <FormattedMessage id="global.webName" defaultMessage={"E-Com365  Go CRM Micro-service"} />
                </div>
                <div className={classes.header_right}>
                    <HomeUser />
                </div>
            </Header>
            <Layout>
                <Sider>
                    {menuNode}
                </Sider>
                <Content>
                    <Outlet />
                    {/* {subscribedServiceList && subscribedServiceList.length ? <Outlet /> : <Spin spinning={true} />} */}
                    <div className={classes.footer}>
                        Copyright @ 2025 BCNetcom. All Rights Reserved
                    </div>
                    <UserProvider />
                </Content>
            </Layout>
        </Layout>
    )
}

export default Profile;