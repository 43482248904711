import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import classes from './homeUser.module.css';
import { Button, Dropdown } from 'antd';
// import {UserOutlined} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useHomeUser } from './useHomeUser';

const HomeUser = props => {

    const navigate = useNavigate();
    const talons = useHomeUser();

    const {
        loginOut,
        goProfile,
        handleMerchantLogin
    } = talons;

    const { userInfo, merchants, isSignedIn } = useSelector((state) => state.user)

    const items = [
        {
            key: '1',
            label: (
                <Button onClick={() => { goProfile() }} style={{ width: '100%' }}>
                    <FormattedMessage id="homeUser.profile" defaultMessage={"Profile"} />
                </Button>
            ),
            extra: '',
        },
        {
            key: '2',
            label: (
                <Button onClick={() => { loginOut() }} style={{ width: '100%' }}>
                    <FormattedMessage id="homeUser.loginout" defaultMessage={"Sign Out"} />
                </Button>
            ),
            extra: '',
        }
    ]

    const mc = Array.from(merchants, item => ({
        key: item.merchantId,
        label: (
            <Button onClick={() => { handleMerchantLogin(item.merchantId) }} style={{ width: '100%' }}>
                {item.merchantCompany}
            </Button>
        ),
        extra: '',
    }))

    return (
        <div className={classes.root}>

            {/* {userInfo && userInfo.merchant ? <div className={classes.company}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.0469 7.42383H13.1484V4.55469C13.1484 3.44727 12.248 2.54883 11.1426 2.54883H4.20899C3.10156 2.54883 2.20312 3.44922 2.20312 4.55469V17.9004H17.8808V9.25782C17.8808 8.2461 17.0586 7.42383 16.0469 7.42383ZM6.2461 16.7149V14.2949C6.2461 14.1816 6.33985 14.0879 6.45312 14.0879H8.89649C9.00976 14.0879 9.10351 14.1816 9.10351 14.2949V16.7149H6.2461ZM10.2774 16.7266V14.2949C10.2774 13.5351 9.6582 12.916 8.89844 12.916H6.45312C5.69336 12.916 5.07422 13.5351 5.07422 14.2949V16.7266H3.37305V4.55469C3.37305 4.09375 3.74805 3.7207 4.20704 3.7207H11.1406C11.6016 3.7207 11.9746 4.0957 11.9746 4.55469V7.42383H11.9727V16.7285H10.2774V16.7266ZM16.7089 16.7266H13.1484V8.5957H16.0469C16.4121 8.5957 16.7109 8.89258 16.7109 9.25977V16.7266H16.7089Z" fill="white" fillOpacity="0.9" />
                    <path d="M9.77734 6.17017H5.57422C5.25001 6.17017 4.98828 6.43188 4.98828 6.7561C4.98828 7.08032 5.25001 7.34204 5.57422 7.34204H9.77539C10.0996 7.34204 10.3613 7.08032 10.3613 6.7561C10.3613 6.43188 10.0996 6.17017 9.77734 6.17017ZM9.77734 9.36157H5.57422C5.25001 9.36157 4.98828 9.62329 4.98828 9.9475C4.98828 10.2717 5.25001 10.5334 5.57422 10.5334H9.77539C10.0996 10.5334 10.3613 10.2717 10.3613 9.9475C10.3633 9.62329 10.0996 9.36157 9.77734 9.36157Z" fill="white" fillOpacity="0.9" />
                </svg>
                {userInfo.merchant.merchantCompany}
            </div> : null} */}

            <Dropdown
                menu={{
                    items: mc,
                }}
                placement="bottom"
                arrow
            >
                <div className={classes.user_root}>
                    <div className={classes.iconBox}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.0469 7.42383H13.1484V4.55469C13.1484 3.44727 12.248 2.54883 11.1426 2.54883H4.20899C3.10156 2.54883 2.20312 3.44922 2.20312 4.55469V17.9004H17.8808V9.25782C17.8808 8.2461 17.0586 7.42383 16.0469 7.42383ZM6.2461 16.7149V14.2949C6.2461 14.1816 6.33985 14.0879 6.45312 14.0879H8.89649C9.00976 14.0879 9.10351 14.1816 9.10351 14.2949V16.7149H6.2461ZM10.2774 16.7266V14.2949C10.2774 13.5351 9.6582 12.916 8.89844 12.916H6.45312C5.69336 12.916 5.07422 13.5351 5.07422 14.2949V16.7266H3.37305V4.55469C3.37305 4.09375 3.74805 3.7207 4.20704 3.7207H11.1406C11.6016 3.7207 11.9746 4.0957 11.9746 4.55469V7.42383H11.9727V16.7285H10.2774V16.7266ZM16.7089 16.7266H13.1484V8.5957H16.0469C16.4121 8.5957 16.7109 8.89258 16.7109 9.25977V16.7266H16.7089Z" fill="white" fillOpacity="0.9" />
                            <path d="M9.77734 6.17017H5.57422C5.25001 6.17017 4.98828 6.43188 4.98828 6.7561C4.98828 7.08032 5.25001 7.34204 5.57422 7.34204H9.77539C10.0996 7.34204 10.3613 7.08032 10.3613 6.7561C10.3613 6.43188 10.0996 6.17017 9.77734 6.17017ZM9.77734 9.36157H5.57422C5.25001 9.36157 4.98828 9.62329 4.98828 9.9475C4.98828 10.2717 5.25001 10.5334 5.57422 10.5334H9.77539C10.0996 10.5334 10.3613 10.2717 10.3613 9.9475C10.3633 9.62329 10.0996 9.36157 9.77734 9.36157Z" fill="white" fillOpacity="0.9" />
                        </svg>
                    </div>
                    <div className={classes.name}>
                        {userInfo && userInfo.merchant && userInfo.merchant.merchantCompany}
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.54028 6.45964L4.45952 5.54041L7.9999 9.08079L11.5403 5.54041L12.4595 6.45964L7.9999 10.9193L3.54028 6.45964Z" fill="white" fillOpacity="0.9" />
                        </svg>
                    </div>
                </div>
            </Dropdown>

            <Dropdown
                menu={{
                    items,
                }}
                placement="bottom"
                arrow
            >
                <div className={classes.user_root}>
                    <div className={classes.iconBox}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.9">
                                <path d="M10 11.25C11.9192 11.25 13.475 9.69419 13.475 7.775C13.475 5.85581 11.9192 4.3 10 4.3C8.08081 4.3 6.525 5.85581 6.525 7.775C6.525 9.69419 8.08081 11.25 10 11.25ZM10 10C8.77117 10 7.775 9.00383 7.775 7.775C7.775 6.54617 8.77117 5.55 10 5.55C11.2288 5.55 12.225 6.54617 12.225 7.775C12.225 9.00383 11.2288 10 10 10Z" fill="white" fillOpacity="0.9" />
                                <path d="M10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75ZM16.2443 14.1557C14.3435 13.0956 12.2319 12.5 9.99997 12.5C7.76802 12.5 5.65649 13.0956 3.75564 14.1557C2.96238 12.9661 2.5 11.5371 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 11.5371 17.0376 12.9662 16.2443 14.1557ZM15.454 15.1482C14.0867 16.5963 12.1489 17.5 10 17.5C7.85112 17.5 5.91332 16.5963 4.54593 15.1482C6.22562 14.247 8.06603 13.75 9.99997 13.75C11.9339 13.75 13.7743 14.247 15.454 15.1482Z" fill="white" fillOpacity="0.9" />
                            </g>
                        </svg>
                    </div>
                    <div className={classes.name}>
                        {userInfo && userInfo.firstName}
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.54028 6.45964L4.45952 5.54041L7.9999 9.08079L11.5403 5.54041L12.4595 6.45964L7.9999 10.9193L3.54028 6.45964Z" fill="white" fillOpacity="0.9" />
                        </svg>
                    </div>
                </div>
            </Dropdown>
        </div>
    )
}

export default HomeUser;