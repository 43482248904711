
import React, { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import Login from '../pages/Login/login';
import PasswordForgot from '../pages/PasswordForgot/passwordForgot';
import PasswordReset from '../pages/PasswordReset/passwordReset';
import PasswordResetSuccess from '../pages/PasswordResetSuccess/passwordResetSuccess';
import Homepage from '../pages/Homepage/homePage';
import InviteAccept from '../pages/InviteAccept/inviteAccept';
import Profile from '../pages/Profile/profile';
const Dashboard = React.lazy(() => import('../components/DashboardPage/dashboard'));
const Settings = React.lazy(() => import('../components/Settings/settings'));
const MerchantUsers = React.lazy(() => import('../components/MerchantUsers/merchantUsers'));
const MerchantVendor = React.lazy(() => import('../components/MerchantVendor/merchantVendor'));
const Marketplaces = React.lazy(() => import('../components/Marketplaces/marketplaces'));
const MerchantAccount = React.lazy(() => import('../components/MerchantAccount/merchantAccount'));
const Security = React.lazy(() => import('../components/Settings/Security/security'));
const MyProfile = React.lazy(() => import('../components/Settings/Profile/profile'));
const Reports = React.lazy(() => import('../components/ReportPage/report'));
// const PageNotFound = React.lazy(() => import('../components/common/PageNotFound/pageNotFound'));



// 实现懒加载的用Suspense包裹 定义函数
const lazyLoad = (children) => {
  return <Suspense fallback={<h1>...</h1>}>
    {children}
  </Suspense>
}

const authorRouter = [

  {
    path: '/',
    messageId: 'router.home',
    defaultMessage: "e-Com365",
    element: <Homepage />,
    auth: true,
    children: [
      {
        path: '/',
        messageId: 'router.dashboard',
        defaultMessage: "Dashboard",
        element: <Navigate to="/index/dashboard" />,
        auth: false
      },
      {
        path: '/index/dashboard',
        messageId: 'router.dashboard',
        defaultMessage: "Dashboard",
        element: lazyLoad(<Dashboard />),
      },
      {
        path: '/index/settings',
        messageId: 'router.settings',
        defaultMessage: "Settings",
        element: lazyLoad(<Settings />),
      },
      {
        path: '/index/merchant-users',
        messageId: 'router.merchantUsers',
        defaultMessage: "Merchant Users",
        element: lazyLoad(<MerchantUsers />),
      },
      {
        path: '/index/merchant-vendor',
        messageId: 'router.MerchantVendor',
        defaultMessage: "Merchant Vendor",
        element: lazyLoad(<MerchantVendor />),
      },
      {
        path: '/index/marketplaces',
        messageId: 'router.marketplaces',
        defaultMessage: "Marketplaces",
        element: lazyLoad(<Marketplaces />),
      },
      {
        path: '/index/reports',
        messageId: 'router.reports',
        defaultMessage: "Reports",
        element: lazyLoad(<Reports />),
      }
    ]
  },
  {
    path: '/profile',
    messageId: 'router.profile',
    defaultMessage: "Profile",
    element: <Profile />,
    children: [
      {
        path: '/profile/',
        messageId: 'router.merchantAccount',
        defaultMessage: "Profile",
        element: <Navigate to="/profile/account" />,
        auth: false
      },
      {
        path: '/profile/account',
        messageId: 'router.merchantAccount',
        defaultMessage: "Merchant Account",
        element: lazyLoad(<MerchantAccount />),
      },
      {
        path: '/profile/my-profile',
        messageId: 'router.myProfile',
        defaultMessage: "My Profile",
        element: lazyLoad(<MyProfile />),
      },
      {
        path: '/profile/security',
        messageId: 'router.security',
        defaultMessage: "Security",
        element: lazyLoad(<Security />),
      },
    ]
  },

  {
    path: '/index',
    messageId: 'router.dashboard',
    defaultMessage: "Dashboard",
    element: <Navigate to="/index/dashboard" />,
    auth: false
  },
  {
    path: '/login',
    messageId: 'router.login',
    defaultMessage: "Login",
    element: <Login />,
  },
  {
    path: '/forgetpassword',
    messageId: 'router.forgetpassword',
    defaultMessage: "Forget Password",
    element: <PasswordForgot />,
  },
  {
    path: '/resetpassword',
    messageId: 'router.resetpassword',
    defaultMessage: "Reset Password",
    element: <PasswordReset />,
  },
  {
    path: '/resetpassword/success',
    messageId: 'router.passwordResetSuccess',
    defaultMessage: "Reset Password Success",
    element: <PasswordResetSuccess />,
  },
  {
    path: '/frontend_user/invite/accept',
    messageId: 'router.inviteAccept',
    defaultMessage: "Invition Accept",
    element: <InviteAccept />,
  },

]

export { authorRouter }