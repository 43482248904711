
import { useCallback, useEffect, useState } from 'react';
import { Form, message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { inviteCheck, register, accept } from '../../api/inviteAcceptApi';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

export const useInviteAccept = props => {

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const [messageApi, contextHolder] = message.useMessage();
    const { formatMessage } = useIntl();

    const [clientReady, setClientReady] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [checkData, setCheckData] = useState(null);
    const [registered, setRegistered] = useState(false);


    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const inviteCode = params.get('code')
        setLoginLoading(true);
        inviteCheck({ inviteToken: inviteCode }).then(res => {
            // console.log('inviteTokenres', res);
            setCheckData({
                isNeedRegister: res.isNeedRegister,
                userEmail: res.userEmail
            });

            if (res.response.isSuccess) {
                form.setFieldsValue({ userEmail: res.userEmail })

            } else {
                message.error({
                    type: 'error',
                    content: res.response.errorMessage,
                });

                setTimeout(()=>{
                    navigate('/login', { replace: false })
                },2000)
            }

            setLoginLoading(false);
        }).catch(error => {
            message.error({
                type: 'error',
                content: error.response.errorMessage,
            });
            console.error(error)
            setLoginLoading(false);
        })

        setClientReady(true);
    }, []);



    const handleRegister = useCallback((values) => {

        setLoginLoading(true);
        const params = new URLSearchParams(window.location.search)
        const inviteCode = params.get('code')
        register({ ...values, inviteToken: inviteCode }).then(res => {
            setRegistered(true);
            setLoginLoading(false);
            setRegistered(true);
            if (res.response.isSuccess) {
                message.success({
                    type: 'success',
                    content: formatMessage({
                        id: "inviteAccept.createSuccess",
                        defaultMessage: "create account success!"
                    }),
                });

            } else {
                message.error({
                    type: 'error',
                    content: res.response.errorMessage,
                });
            }

        }).catch(error => {
            messageApi.error({
                type: 'error',
                content: error.response.errorMessage,
            });
            console.error(error)
            setLoginLoading(false);
        })

    }, [navigate, form]);

    const handleAccept = useCallback((values) => {

        setLoginLoading(true);
        const params = new URLSearchParams(window.location.search)
        const inviteCode = params.get('code')
        accept({ ...values, inviteToken: inviteCode }).then(res => {

            if (res.response.isSuccess) {
                message.success({
                    type: 'success',
                    content: formatMessage({
                        id: "inviteAccept.AcceptionSuccess",
                        defaultMessage: "Acception Success"
                    }),
                });

            } else {
                message.error({
                    type: 'error',
                    content: res.response.errorMessage,
                });
            }

            setRegistered(true);
            setLoginLoading(false);
            // setRegistered(true);
            navigate('/login', { replace: false })
        }).catch(error => {
            console.error(error)
            setLoginLoading(false);
        })

    }, [navigate, form]);

   

    const handleForgetPassWord = useCallback(() => {
        navigate('/forgetpassword', { replace: false })
    })


    return {
        form,
        clientReady,
        loginLoading,
        handleRegister,
        errorMsg,
        showErrorMsg,
        setShowErrorMsg,
        handleForgetPassWord,
        checkData,
        registered,
        handleAccept
    };

}