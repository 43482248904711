import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setToken, setIsLoginCompleted, setIsMerchantLoginCompleted, setUserInfo, setPermissions, setMenuList, setSubscribedServiceList, setMerchants } from './redux/userSlice'
import { message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { getMenuList, getMerchantSubscribedServiceList, getMerchantPermissions } from './api/menuApi';
import { getProfile, merchantLogin } from './api/globalLoginApi';
// import { merchantLogin } from './api/merchantUsersApi';

function MerchantProvider() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isSignedIn } = useSelector((state) => state.user);

    useEffect(() => {
        // const pathname = window.location.pathname + window.location.search + window.location.hash;
        const params = new URLSearchParams(window.location.href);
        // const { redirectMerchantToken } = location.state || {};
        // const paramsLoginToken = params.get("merchantcode") || redirectMerchantToken;
        // const mcid = params.get("mcid");
        const storageLoginToken = localStorage.getItem("MERCHANT_LOGIN_TOKEN");
        const storageMcid = localStorage.getItem("MERCHANT_ID");
        const token = storageLoginToken;
        const merchantId = storageMcid;

        if (token && merchantId) {
            merchantLogin({ merchantId: merchantId }).then(resProfile => {
                if (resProfile && resProfile.response.isSuccess) {
                    dispatch(setUserInfo({
                        ...resProfile.user,
                        merchant: resProfile.merchant,
                        // merchants: resProfile.merchants,
                        // hasMarketplaceService: resProfile.hasMarketplaceService,
                        // hasReleatedMarkets: resProfile.hasReleatedMarkets,
                        // isVendorLogin: resProfile.isVendorLogin,
                    }))
                    dispatch(setToken(token))

                } else {
                    message.error({
                        type: 'error',
                        content: resProfile.response.errorMessage,
                    });
                }

                // dispatch(setIsMerchantLoginCompleted(true))


            }).catch(error => {
                message.error({
                    type: 'error',
                    content: error.message,
                });
                console.error(error)
                // dispatch(setIsMerchantLoginCompleted(true))
            })

            getProfile({ merchantId: merchantId }).then(resProfile => {
                if (resProfile && resProfile.response.isSuccess) {
                    dispatch(setMerchants(
                        resProfile.merchants,
                    ))
                } else {
                    message.error({
                        type: 'error',
                        content: resProfile.response.errorMessage,
                    });
                }

            }).catch(error => {
                message.error({
                    type: 'error',
                    content: error.message,
                });
                console.error(error)
            })


        } else {

            // if (!loginToken && !pathname.includes("/login") && !pathname.includes("/forgetpassword") && !pathname.includes("/resetpassword") && !pathname.includes("/resetpassword/success") && !pathname.includes("/frontend_user/invite/accept"))
            if (process.env.NODE_ENV == "production") {
                window.location.href = `${window.location.origin}/login`;
            } else {
                navigate("/profile/account", { replace: true })
            }


        }
    }, []);

    useEffect(() => {
        if (isSignedIn) {
            getMenuList().then(res => {
                if (res && res.menu) {
                    dispatch(setMenuList(res.menu.children))
                }
                else {
                    dispatch(setMenuList([]))

                }
            }).catch(error => {
                console.error(error)
            })

            getMerchantSubscribedServiceList(
                {
                    "pageNumber": 1,
                    "pageSize": 1000,
                    "filters": [
                        {
                            "fieldName": "instance_id",
                            "operator": ">",
                            "value": "0"
                        }
                    ],
                    "sort": {
                        "fieldName": "instance_id",
                        "sort": "asc"
                    }
                }
            ).then(res => {
                if (res && res.users) {
                    localStorage.setItem('subscribedServiceList', JSON.stringify(res.users)
                    )
                    dispatch(setSubscribedServiceList(res.users))
                }
                else {
                    localStorage.setItem('subscribedServiceList', [])
                    dispatch(setSubscribedServiceList([]))
                }
                dispatch(setIsMerchantLoginCompleted(true))

            }).catch(error => {
                dispatch(setIsMerchantLoginCompleted(true))

                console.error(error)
            })

            getMerchantPermissions().then(res => {
                if (res && res.permission) {
                    dispatch(setPermissions(res.permission))
                }
                else {
                    dispatch(setPermissions([]))
                }
            }).catch(error => {
                console.error(error)
            })



        }

    }, [isSignedIn])

    return (
        <></>
    );
}

export default MerchantProvider;
