import React, { useEffect } from 'react';
import classes from './inviteAccept.module.css';
import { Form, message } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { EyeOutlined } from '@ant-design/icons';

import { useInviteAccept } from './useInviteAccept';
import TextInput from '../../components/common/formDesign/TextInput/textInput';
import LandingWelcome from '../../components/LandingWelcome/landingWelcome';

import ButtonNode from '../../components/common/formDesign/ButtonNode/buttonNode';
import CheckboxNode from '../../components/common/formDesign/CheckboxNode/checkboxNode';

import {
    IconUserGreySvg,
    IconPasswordLockGreySvg,
    IconPasswordHideGreySvg,
    IconPasswordVisibleGreySvg
} from '../../components/common/IconsSvg/iconsSvg';

const InviteAccept = props => {

    const { formatMessage } = useIntl();
    const [messageApi, contextHolder] = message.useMessage();

    const talons = useInviteAccept();

    const {
        form,
        clientReady,
        loginLoading,
        onStartLogin,
        errorMsg,
        showErrorMsg,
        setShowErrorMsg,
        handleForgetPassWord,
        checkData,
        handleRegister,
        registered,
        handleAccept
    } = talons;

    useEffect(() => {
        if (showErrorMsg) {
            messageApi.open({
                type: 'error',
                content: errorMsg,
            });
            setShowErrorMsg(false);
        }

    }, [showErrorMsg, errorMsg, messageApi, setShowErrorMsg]);

    if (checkData && checkData.isNeedRegister && !registered) {
        return (
            <div className={classes.root}>
                {contextHolder}
                <aside className={classes.panel}>
                    <div className={classes.panel_left}>
                        <LandingWelcome title={formatMessage({
                            id: "inviteAccept.inviteAcceptTitle",
                            defaultMessage: "Accept Invitation"
                        })} />
                    </div>
                    <div className={classes.panel_right}>
                        <div className={classes.right_box}>
                            <div className={classes.right_content}>
                                <h1>
                                    <FormattedMessage id="inviteAccept.register" defaultMessage={"Sign up"} />
                                </h1>
                                <Form className={classes.form} form={form} name="login_form" onFinish={handleRegister} layout='vertical'>
                                    <TextInput
                                        name="userEmail"
                                        rules={[{
                                            required: true,
                                            message: formatMessage({
                                                id: "form.isRequired",
                                                defaultMessage: "{name} is required!"
                                            }, {
                                                name: formatMessage({
                                                    id: "form.email",
                                                    defaultMessage: "Email"
                                                })
                                            })
                                        }]}
                                        placeholder={formatMessage({
                                            id: "form.placeholder",
                                            defaultMessage: "Please enter {name} "
                                        }, {
                                            name: formatMessage({
                                                id: "form.emailPlaceholder",
                                                defaultMessage: "Email"
                                            })
                                        })}
                                        styles={{
                                            flexDirection: 'column'
                                        }}
                                        prefix={<IconUserGreySvg />}
                                        disabled
                                    />

                                    <TextInput
                                        name="firstName"
                                        rules={[{
                                            required: true,
                                            message: formatMessage({
                                                id: "form.isRequired",
                                                defaultMessage: "{name} is required!"
                                            }, {
                                                name: formatMessage({
                                                    id: "form.firstName",
                                                    defaultMessage: "First name"
                                                })
                                            })
                                        }]}
                                        styleType='wrap'
                                        label={formatMessage({
                                            id: "form.firstName",
                                            defaultMessage: "First name"
                                        })}
                                        placeholder={formatMessage({
                                            id: "form.placeholder",
                                            defaultMessage: "Please enter {name} "
                                        }, {
                                            name: formatMessage({
                                                id: "form.firstName",
                                                defaultMessage: "first name"
                                            })
                                        })}

                                    />

                                    <TextInput
                                        name="lastName"
                                        rules={[{
                                            required: true,
                                            message: formatMessage({
                                                id: "form.isRequired",
                                                defaultMessage: "{name} is required!"
                                            }, {
                                                name: formatMessage({
                                                    id: "form.lastName",
                                                    defaultMessage: "Last name"
                                                })
                                            })
                                        }]}
                                        styleType='wrap'
                                        label={formatMessage({
                                            id: "form.lastName",
                                            defaultMessage: "Last name"
                                        })}
                                        placeholder={formatMessage({
                                            id: "form.placeholder",
                                            defaultMessage: "Please enter {name} "
                                        }, {
                                            name: formatMessage({
                                                id: "form.lastName",
                                                defaultMessage: "last name"
                                            })
                                        })}

                                    />


                                    <TextInput
                                        name="password"
                                        rules={[{
                                            required: true,
                                            message: formatMessage({
                                                id: "form.isRequired",
                                                defaultMessage: "{name} is required!"
                                            }, {
                                                name: formatMessage({
                                                    id: "form.password",
                                                    defaultMessage: "Password"
                                                })
                                            })
                                        }]}
                                        styleType='wrap'
                                        inputType="password"
                                        label={formatMessage({
                                            id: "form.newPassword",
                                            defaultMessage: "New pPassword"
                                        })}
                                        placeholder={formatMessage({
                                            id: "form.placeholder",
                                            defaultMessage: "Please enter {name} "
                                        }, {
                                            name: formatMessage({
                                                id: "form.newPasswordPlaceholder",
                                                defaultMessage: "new password"
                                            })
                                        })}
                                        prefix={<IconPasswordLockGreySvg />}

                                        iconRender={(visible) => (visible ? IconPasswordVisibleGreySvg
                                            : IconPasswordHideGreySvg)}
                                    />
                                    <TextInput
                                        name="passwordConfirm"
                                        rules={[{
                                            required: true,
                                            message: formatMessage({
                                                id: "form.isRequired",
                                                defaultMessage: "{name} is required!"
                                            }, {
                                                name: formatMessage({
                                                    id: "form.confirmPassword",
                                                    defaultMessage: "Confirm password"
                                                })
                                            })
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error(formatMessage({
                                                    id: "setting.passwordNotMatch",
                                                    defaultMessage: "The new password that you entered do not match!"
                                                })));
                                            },
                                        })
                                        ]}
                                        styleType='wrap'
                                        inputType="password"
                                        label={formatMessage({
                                            id: "form.confirmPassword",
                                            defaultMessage: "Confirm password"
                                        })}
                                        placeholder={formatMessage({
                                            id: "form.placeholder",
                                            defaultMessage: "Please enter {name} "
                                        }, {
                                            name: formatMessage({
                                                id: "form.confirmPasswordPlaceholder",
                                                defaultMessage: "confirm password"
                                            })
                                        })}
                                        prefix={<IconPasswordLockGreySvg />}
                                        iconRender={(visible) => (visible ? IconPasswordVisibleGreySvg
                                            : IconPasswordHideGreySvg)}
                                    />
                                    {/* <div className={classes.form_other}>
                                        <CheckboxNode
                                            name="remember"
                                            valuePropName="checked"
                                        >
                                            <FormattedMessage id="inviteAccept.rememberMeLabel" defaultMessage={"Remember me"} />
                                        </CheckboxNode>
                                        <ButtonNode
                                            type="link"
                                            onClick={handleForgetPassWord}
                                        >
                                            <FormattedMessage id="inviteAccept.forgetBtn" defaultMessage={"Forget the password ?"} />
                                        </ButtonNode>
                                    </div> */}
                                    <Form.Item shouldUpdate>
                                        {() => (
                                            <ButtonNode
                                                type="primary"
                                                htmlType="submit"
                                                disabled={
                                                    !clientReady ||
                                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                                }
                                                loading={loginLoading}
                                                style={{ width: '100%' }}
                                            >
                                                <FormattedMessage id="inviteAccept.signup" defaultMessage={"Sign up"} />
                                            </ButtonNode>
                                        )}
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                </aside>
            </div>
        )

    }


    return (
        <div className={classes.root}>
            {contextHolder}
            <aside className={classes.panel}>
                <div className={classes.panel_left}>
                    <LandingWelcome title={formatMessage({
                        id: "inviteAccept.inviteAcceptTitle",
                        defaultMessage: "Accept Invitation"
                    })} />
                </div>
                <div className={classes.panel_right}>
                    <div className={classes.right_box}>
                        <div className={classes.right_content}>
                            <h1>
                                <FormattedMessage id="inviteAccept.acceptInvitation" defaultMessage={"accept invitation"} />
                            </h1>
                            <Form className={classes.form} form={form} name="login_form" onFinish={handleAccept} layout='vertical'>
                                <TextInput
                                    name="userEmail"
                                    rules={[{
                                        required: true,
                                        message: formatMessage({
                                            id: "form.isRequired",
                                            defaultMessage: "{name} is required!"
                                        }, {
                                            name: formatMessage({
                                                id: "form.email",
                                                defaultMessage: "Email"
                                            })
                                        })
                                    }]}
                                    placeholder={formatMessage({
                                        id: "form.placeholder",
                                        defaultMessage: "Please enter {name} "
                                    }, {
                                        name: formatMessage({
                                            id: "form.emailPlaceholder",
                                            defaultMessage: "Email"
                                        })
                                    })}
                                    styles={{
                                        flexDirection: 'column'
                                    }}
                                    prefix={<IconUserGreySvg />}
                                    disabled
                                />


                                <Form.Item shouldUpdate>
                                    {() => (
                                        <ButtonNode
                                            type="primary"
                                            htmlType="submit"
                                            disabled={
                                                !clientReady ||
                                                !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                            }
                                            loading={loginLoading}
                                            style={{ width: '100%' }}
                                        >
                                            <FormattedMessage id="inviteAccept.accept" defaultMessage={"Accept"} />
                                        </ButtonNode>
                                    )}
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </aside>
        </div>
    )
}

export default InviteAccept;