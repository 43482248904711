import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setToken, setIsLoginCompleted, setUserInfo, setMerchants } from './redux/userSlice'
import { useNavigate, useLocation } from 'react-router-dom';
// import { getMenuList, getMerchantSubscribedServiceList, getMerchantPermissions } from './api/menuApi';
import { getProfile } from './api/globalLoginApi';

function UserProvider() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isSignedIn } = useSelector((state) => state.user);
    const [loginToken, setLoginToken] = useState(localStorage.getItem("LOGIN_TOKEN"));

    useEffect(() => {
        const params = new URLSearchParams(window.location.href);
        // const { redirectToken } = location.state || {};
        const paramsLoginToken = params.get("code");
        const storageLoginToken = localStorage.getItem("LOGIN_TOKEN");

        if (paramsLoginToken) {
            setLoginToken(paramsLoginToken)

            const token = {
                timeStored: new Date().getTime(),
                ttl: 86400,
                value: paramsLoginToken
            }

            localStorage.setItem("LOGIN_TOKEN", JSON.stringify(token));
        } else if (storageLoginToken) {
            try {
                const storageToken = JSON.parse(storageLoginToken);
                setLoginToken(storageToken.value);
            } catch (error) {
                setLoginToken('');
            }

        }


    }, [location.state]);

    useEffect(() => {
        // const pathname = window.location.pathname + window.location.search + window.location.hash;
        if (loginToken) {

            getProfile().then(resProfile => {
                if (resProfile && resProfile.response.isSuccess) {
                    dispatch(setUserInfo({
                        ...resProfile.user,
                        // merchant: resProfile.merchant,
                        // merchants: resProfile.merchants,
                        // hasMarketplaceService: resProfile.hasMarketplaceService,
                        // hasReleatedMarkets: resProfile.hasReleatedMarkets,
                        // isVendorLogin: resProfile.isVendorLogin,
                    }))

                    dispatch(setMerchants(
                        resProfile.merchants,
                    ))
                    dispatch(setToken(loginToken))
                    dispatch(setIsLoginCompleted(true))

                    // const currentPath = pathname.replace(process.env.REACT_APP_BASE_NAME, '')
                    // navigate(currentPath, { replace: false })

                } else {
                    dispatch(setIsLoginCompleted(true))
                }

            }).catch(error => {
                console.error(error)
                dispatch(setIsLoginCompleted(true))
            })
        } else {
            if (process.env.NODE_ENV == "production") {
                window.location.href = `${window.location.origin}/login`;
            } else {
                navigate("/login", { replace: true })
            }

        }
    }, [loginToken]);

    return (
        <></>
    );
}

export default UserProvider;
