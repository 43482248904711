
import React, { useEffect } from 'react';
import defaultClasses from './passwordForgot.module.css';
import loginClasses from '../Login/login.module.css';
import { useStyle } from '../../utils/classify';

import { Form, message, Divider, Alert } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { EyeOutlined } from '@ant-design/icons';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';

import { useForgetPassword } from './usePasswordForgot';
import TextInput from '../../components/common/formDesign/TextInput/textInput';
import LandingWelcome from '../../components/LandingWelcome/landingWelcome';
import ButtonNode from '../../components/common/formDesign/ButtonNode/buttonNode';

const PasswordForgot = props => {

    const { formatMessage } = useIntl();
    const [messageApi, contextHolder] = message.useMessage();

    const talons = useForgetPassword();

    const {
        form,
        clientReady,
        loginLoading,
        onStartLogin,
        errorMsg,
        showErrorMsg,
        setShowErrorMsg,
        handleNavigateBack,
        handleCloseMessage,
        handleReCaptchaVerify,
        refreshReCaptcha,
        reCaptchaKey,
        recaptchaToken,
        reCaptchaEnable
    } = talons;

    // useEffect(() => {
    //     if (showErrorMsg) {
    //         messageApi.open({
    //             type: 'error',
    //             content: errorMsg,
    //         });
    //         setShowErrorMsg(false);
    //     }

    // }, [showErrorMsg, errorMsg, messageApi, setShowErrorMsg]);

    const classes = useStyle(loginClasses, defaultClasses, props.classes);

    return (
        <div className={classes.root}>
            {contextHolder}
            <aside className={classes.panel}>
                <div className={classes.panel_left}>
                    <LandingWelcome />
                </div>
                <div className={classes.panel_right}>
                    <div className={classes.right_box}>
                        <div className={classes.right_content}>
                            <h1>
                                <FormattedMessage id="passwordForget.passwordReset" defaultMessage={"Password Reset"} />
                            </h1>
                            <div className={classes.right_top_create}>
                                <FormattedMessage id="passwordForget.panelRightSpan" defaultMessage={"Don’t have an account?"} />
                            </div>
                            <Form className={classes.form} form={form} name="login_form" onFinish={onStartLogin} layout='vertical'>
                                <TextInput
                                    name="email"
                                    rules={[{
                                        required: true,
                                        message: formatMessage({
                                            id: "form.isRequired",
                                            defaultMessage: "{name} is required!"
                                        }, {
                                            name: formatMessage({
                                                id: "form.email",
                                                defaultMessage: "email"
                                            })
                                        })
                                    }]}
                                    styleType='wrap'
                                    label={formatMessage({
                                        id: "form.email",
                                        defaultMessage: "Email"
                                    })}
                                    placeholder={formatMessage({
                                        id: "form.placeholder",
                                        defaultMessage: "Please enter {name} "
                                    }, {
                                        name: formatMessage({
                                            id: "form.emailPlaceholder",
                                            defaultMessage: "email"
                                        })
                                    })}
                                />

                                {showErrorMsg ? <div className={classes.form_other}>
                                    <Alert
                                        message=""
                                        description={errorMsg}
                                        type="error"
                                        closable={true}
                                        onClose={handleCloseMessage}
                                        style={{ width: '100%' }}
                                    />
                                </div> : ''}

                                {reCaptchaEnable ? <div style={{ marginBottom: '16px' }}>
                                    <GoogleReCaptchaProvider
                                        reCaptchaKey={reCaptchaKey}
                                        language="en"
                                        scriptProps={{
                                            async: false, // optional, default to false,
                                            defer: false, // optional, default to false
                                            appendTo: 'head', // optional, default to "head", can be "head" or "body",
                                            nonce: undefined // optional, default undefined
                                        }}
                                        container={{ // optional to render inside custom element
                                            element: "GoogleReCaptchaProvider",
                                            parameters: {
                                                badge: 'inline', // optional, default undefined
                                                theme: 'dark', // optional, default undefined
                                            }
                                        }}
                                    >
                                        <div id='GoogleReCaptchaProvider'></div>
                                        <GoogleReCaptcha
                                            onVerify={handleReCaptchaVerify}
                                            refreshReCaptcha={refreshReCaptcha}
                                            action="send_verify_code"
                                        />
                                    </GoogleReCaptchaProvider>
                                </div> : null}

                                <Form.Item shouldUpdate>
                                    {() => (
                                        <ButtonNode
                                            type="primary"
                                            htmlType="submit"
                                            disabled={
                                                !recaptchaToken && reCaptchaEnable || !clientReady ||
                                                !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                            }
                                            loading={loginLoading}
                                            style={{ width: '100%' }}
                                        >
                                            <FormattedMessage id="passwordForget.passwordReset" defaultMessage={"Password Reset"} />
                                        </ButtonNode>
                                    )}
                                </Form.Item>

                                <Divider />
                                <div className={classes.form_other}>
                                    <div className={classes.remembered}>
                                        <FormattedMessage id="passwordForget.rememberedPassword" defaultMessage={"Remembered your Password?"} />
                                    </div>
                                </div>

                                <div className={classes.form_other}>
                                    <ButtonNode
                                        type="default"
                                        onClick={handleNavigateBack}
                                    >
                                        <FormattedMessage id="passwordForget.navigateBack" defaultMessage={"Back to Sign In"} />
                                    </ButtonNode>
                                </div>
                            </Form>
                        </div>
                        {/* <div className={classes.right_bottom}>
                            <FormattedMessage id="global.copyright " defaultMessage={"Copyright @ 2024 BCNetcom. All Rights Reserved"} />
                        </div> */}
                    </div>
                </div>
            </aside>
        </div>
    )
}

export default PasswordForgot;