
import { useCallback, useEffect, useState } from 'react';
import { Form, message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { userLogin, getProfile } from '../../api/loginApi';
import { useSelector, useDispatch } from 'react-redux';
import { setToken, setIsLoginCompleted, setUserInfo } from '../../redux/userSlice'
import { getGooleConfig } from '../../api/googleReCaptchaApi';

export const useLogin = props => {

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const dispatch = useDispatch()

    const [clientReady, setClientReady] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const [reCaptchaKey, setReCaptchaKey] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const [reCaptchaEnable, setReCaptchaEnable] = useState(false);


    const getConfig = useCallback((values) => {
        getGooleConfig(values).then(res => {
            setReCaptchaKey(res.response.reCaptchaKey);
            res.items.forEach((item) => {
                if (item.key === 'recaptcha.is_enable') {
                    setReCaptchaEnable(Number(item.value));
                }
                if (item.key === 'recaptcha.site_key') {
                    setReCaptchaKey(item.value);
                }
            })

        }).catch(error => {
            console.log('error', error);

        })
    })

    useEffect(() => {
        setClientReady(true);
        getConfig()
    }, []);


    const onStartLogin = useCallback(async (values) => {
        setLoginLoading(true);
        setShowErrorMsg(false);



        userLogin({
            ...values,
            recaptchaToken: recaptchaToken,
        }).then(res => {
            setLoginLoading(false);
            if (res && res.response.isSuccess) {
                localStorage.setItem("userLocalData", JSON.stringify({ email: values.email }));

                const token = {
                    timeStored: new Date().getTime(),
                    ttl: 86400,
                    value: res.token
                }
                localStorage.setItem("LOGIN_TOKEN", JSON.stringify(token));
                // dispatch(setToken(res.token))
                // window.location.href = res.merchant.merchantFrontendUrl && process.env.NODE_ENV == "production" ? `${res.merchant.merchantFrontendUrl}/?token=${res.token}` : `${window.location.origin}/index/dashboard?token=${res.token}`;
                navigate(`/profile/account?code=${res.token}`, { replace: true })

            } else {
                setShowErrorMsg(true);
                setErrorMsg(res.response.errorMessage);
                setRefreshReCaptcha(!refreshReCaptcha);
                // message.error({
                //     type: 'error',
                //     content: res.response.errorMessage,
                // });
            }
        }).catch(error => {
            setShowErrorMsg(true);
            // setRefreshReCaptcha(true);
            setErrorMsg(error.message);
            setRefreshReCaptcha(!refreshReCaptcha);
            // message.error({
            //     type: 'error',
            //     content: error.response.errorMessage,
            // });
            console.error(error)
            setLoginLoading(false);
        })

    }, [navigate, form, recaptchaToken, refreshReCaptcha]);

    const handleReCaptchaVerify = useCallback(async (token) => {
        setRecaptchaToken(token)
    }, []);

    useEffect(() => {
        const userLocalData = localStorage.getItem("userLocalData");
        if (userLocalData) {
            const userSaveData = JSON.parse(userLocalData);
            if (userSaveData && userSaveData.remember) {
                form.setFieldsValue(userSaveData)
            }
        }
    }, []);

    const handleForgetPassWord = useCallback(() => {
        navigate('/forgetpassword', { replace: false })
    })

    const handleCloseMessage = useCallback(() => {
        setShowErrorMsg(false);
    })


    return {
        form,
        clientReady,
        loginLoading,
        onStartLogin,
        errorMsg,
        showErrorMsg,
        setShowErrorMsg,
        handleForgetPassWord,
        handleCloseMessage,
        reCaptchaKey,
        handleReCaptchaVerify,
        refreshReCaptcha,
        recaptchaToken,
        reCaptchaEnable
    };

}