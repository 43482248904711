import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        token: null,
        userInfo: {},
        isSignedIn: false,
        isLoginCompleted: false,
        isMerchantLoginCompleted: false,
        permissions: [],
        menuList: [],
        roleList: [],
        subscribedServiceList: [],
        merchants: []
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload
            state.isSignedIn = true
        },
        setIsLoginCompleted: (state, action) => {
            state.isLoginCompleted = action.payload
        },
        setIsMerchantLoginCompleted: (state, action) => {
            state.isMerchantLoginCompleted = action.payload
        },
        clearToken: (state) => {
            state.token = null
            state.isSignedIn = false
            state.userInfo = {}
            state.isSignedIn = false
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload
        },
        clearUserInfo: (state) => {
            state.userInfo = {}
        },
        setMerchants: (state, action) => {
            state.merchants = action.payload
        },
        clearMerchants: (state) => {
            state.merchants = []
        },
        setPermissions: (state, action) => {
            state.permissions = action.payload
        },
        setMenuList: (state, action) => {
            state.menuList = action.payload
        },
        setSubscribedServiceList: (state, action) => {
            state.subscribedServiceList = action.payload
        },

    },
})

// Action creators are generated for each case reducer function
export const { setToken, setIsLoginCompleted, setIsMerchantLoginCompleted, clearToken, setUserInfo, clearUserInfo, setPermissions, setMenuList, setSubscribedServiceList, setMerchants, clearMerchants } = userSlice.actions

export default userSlice.reducer