
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { userLogin, getProfile } from '../../api/loginApi';
import { useSelector, useDispatch } from 'react-redux';
import { setToken, setUserInfo, clearToken, clearUserInfo } from '../../../redux/userSlice'
// import { getPermissionsList } from '../../api/roleApi';
import { merchantLogin } from '../../../api/globalLoginApi';


export const useHomeUser = props => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const loginOut = useCallback(() => {
        localStorage.removeItem("LOGIN_TOKEN");
        localStorage.removeItem("MERCHANT_LOGIN_TOKEN");

        dispatch(clearToken())
        dispatch(clearUserInfo())
        // window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/login`
        window.location.href = `${window.location.origin}/login`;

    }, [navigate]);

    const goProfile = useCallback(() => {
        window.location.href = `${window.location.origin}/profile/account`;

    }, [navigate]);

    const handleMerchantLogin = useCallback((merchantId) => {
        setLoading(true);
        merchantLogin({ merchantId: merchantId }).then(res => {
            setLoading(false);

            const token = {
                timeStored: new Date().getTime(),
                ttl: 86400,
                value: res.token
            }

            localStorage.setItem("MERCHANT_LOGIN_TOKEN", JSON.stringify(token));
            localStorage.setItem("MERCHANT_ID", merchantId);

            window.location.href = res.merchant.merchantFrontendUrl && process.env.NODE_ENV == "production" ? `${res.merchant.merchantFrontendUrl}/` : `${window.location.origin}/index/dashboard`;

        }).catch(error => {
            console.error(error)
            setLoading(false);
        })
    })


    return {
        loginOut,
        goProfile,
        handleMerchantLogin
    };

}