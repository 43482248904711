
import { post, get } from '../utils/http';

export function userLogin(param) {
    return post('admin_merchant/v1/frontend_user/login', param, 'default');
}

export function getProfile(id, param) {
    return get(`admin_merchant/v1/frontend_user/profile`, param, 'default');
}


//用户登录
export function merchantLogin(param) {
    return post('admin_merchant/v1/merchant_user/login', param, 'default');
};

export function getPhoneAreaList(param) {
    return get('admin_merchant/v1/general/phone_area/list', param, 'default');
}