
const en_US = {
    "global.webName": "e-Com365  Go CRM Micro-service",
    "global.searchBtn": "Filter",
    "global.addBtn": "Create",
    "global.backBtn": "Back",
    "global.male": "Male",
    "global.female": "Female",
    "global.apiError": "Unknown error, please try again",
    "global.apiEditSuccess": "Edit success!",
    "global.apiaddSuccess": "Create success!",
    "global.apidelSuccess": "Delete success!",
    "global.apisendSuccess": "Send success!",
    "global.confirmDelModalTitle": "Delete items",
    "global.systemLandingTitle": "Welcome back to merchant portal",
    "global.systemLandingMessage": "e-Com365 provides you enterprise level turn key solution on E-Commerce, CRM, marketing automation and AI business analytics.",
    "global.delete": "Delete",
    "global.noSubscribes": "You do not have a subscription service, please contact the merchant.",

    "form.isRequired": "{name} is required!",
    "form.isValid": "Please input a valid {name}",
    "form.placeholder": "Please enter {name}",
    "form.select": "Select",
    "form.cancel": "Cancel",
    "form.ok": "Save",
    "form.delete": "Delete",
    "form.okBtn": "Ok",
    "form.email": "Email",
    "form.emailPlaceholder": "email",
    "form.password": "Password",
    "form.passwordPlaceholder": "password",
    "form.newPassword": "New password",
    "form.newPasswordPlaceholder": "new password",
    "form.confirmPasswordPlaceholder": "confirm password ",
    "form.confirmPassword": "Confirm password ",
    "form.currentPassword": "Current password",
    "form.currentPasswordPlaceholder": "current password",
    "form.firstnamePlaceholder": "first name",
    "form.firstname": "First name",
    "form.lastname": "Last name",
    "form.lastnamePlaceholder": "last name",
    "form.userEmail": "User email",
    "form.lastName": "Last name",
    "form.firstName": "First name",
    "form.merchantCompany": "Merchant company",
    "form.company": "Company",
    "form.companyPlaceholder": "company",
    "form.merchantContactEmail": "Merchant contact email",
    "form.reset": "Reset",
    "form.all": "All",
    "form.vendorMerchantId": "Vendor merchant",
    "form.vendorContactEmail": "Vendor contact email",
    "form.vendorContactPhone": "Vendor contact phone",
    "form.phonePlaceholder": "phone",
    "form.merchantId": "Merchant",
    "form.vendorCode": "Vendor code",
    "form.vendorCodePlaceholder": "vendor code",
    "form.vendorName": "Vendor name",
    "form.vendorNamePlaceholder": "vendor name",
    "form.isMarketplace": "Is market place",
    "form.contactEmailPlaceholder": "contact email",
    "form.phoneArea": "Country code",
    "form.contactPhone": "Contact phone",
    "form.serviceCode": "Service code",
    "form.serviceCodePlaceholder": "service code",
    "form.serviceName": "Service name",
    "form.serviceNamePlaceholder": "service name",

    "login.panelName": "Login",
    "login.loginBtn": "Login",
    "login.panelRightSpan": "Don’t have an account?",
    "login.panelRightSpanButton": "Register a new account",
    "login.rememberMeLabel": "Remember me",
    "login.forgetBtn": "Forget password ?",

    "passwordForget.passwordReset": "Password reset",
    "passwordForget.panelRightSpan": "We will help you reset your password",
    "passwordForget.rememberedPassword": "Remembered your password?",
    "passwordForget.navigateBack": "Back to sign in",
    "passwordForget.label_code": "Verification code",
    "passwordForget.placeholder_code": "verification code",
    "passwordReset.passwordReset": "Password reset",
    "passwordReset.panelRightSpan": "We will help you reset your password",
    "passwordReset.rememberedPassword": "Remembered your password?",
    "passwordReset.navigateBack": "Back to sign in",
    "passwordReset.passwordNotMatch": "The new password that you entered do not match!",
    "passwordResetSuccess.successful": "Successful",
    "passwordResetSuccess.panelRightSpan": "The password has been changed successfully",
    "passwordResetSuccess.navigateBack": "Back to sign in",

    "router.dashboard": "Dashboard",
    "router.profile": "Profile",
    "router.merchantAccount": "Merchant account",
    "router.customer": "Customer",
    "router.customerSegment": "Customer segment",
    "router.customerAttribute": "Customer attribute",
    "router.customerSubscribe": "Customer subscribers",
    "router.customerGroup": "Customer group",
    "router.campaigns": "Campaigns",
    "router.campaignSettings": "Campaign settings",
    "router.whatsappTemplate": "Whatsapp template",
    "router.reports": "Reports",
    "router.orders": "Orders",
    "router.ordersConfig": "Order config",
    "router.promotionSettings": "Promotion settings",
    "router.login": "Login",
    "router.forgetpassword": "Forget password",
    "router.resetpassword": "Reset password",
    "router.passwordResetSuccess": "Reset password success",
    "router.merchantUsers": "Users",
    "router.merchantVendor": "Vendor",
    "router.settings": "Settings",
    "router.pageNotFound": "Page not found",
    "router.marketplaces": "Marketplaces",
    "router.PageBuilder": "Page bbuilder",
    "router.b2bDashboard": "Dashboard",
    "router.b2bSalesCenter": "B2B sales center",

    "homeMenu.dashboard": "Dashboard",
    "homeMenu.customer": "Customer",
    "homeMenu.allCustomers": "All customers",
    "homeMenu.segment": "Segment",
    "homeMenu.customerAttribute": "Attribute",
    "homeMenu.customerSubscribe": "Subscribers",
    "homeMenu.customerSegment": "Customer segment",
    "homeMenu.campaign": "Campaign",
    "homeMenu.campaigns": "Campaigns",
    "homeMenu.journeys": "Journeys",
    "homeMenu.journey": "Journey",
    "homeMenu.campaignSettings": "Settings",
    "homeMenu.whatsappTemplate": "Whatsapp template",
    "homeMenu.wTemplateCreate": "Create template",
    "homeMenu.promotion": "Promotion",
    "homeMenu.orders": "Orders",
    "homeMenu.customerConfig": "Config",
    "homeMenu.allOrders": "All orders",
    "homeMenu.reports": "Reports",
    "homeMenu.main": "Main",
    "homeMenu.push": "Push",
    "homeMenu.company": "Company",
    "homeMenu.role": "Role",
    "homeMenu.user": "Users",
    "homeUser.loginout": "Sign out",
    "homeUser.profile": "My profile",
    "homeMenu.merchantUsers": "Merchant users",
    "homeMenu.PageBuilder": "Page builder",
    "homeMenu.allPageBuilder": "All pages",
    "homeMenu.reward": "Reward",
    "homeMenu.warranty": "Warranty",
    "homeMenu.warrantyRecord": "Warranty records",
    "homeMenu.warrantyProduct": "Products management",
    "homeMenu.warrantyForm": "Registration form",
    "homeMenu.merchantAccount": "Account",
    "homeMenu.myProfile": "My profile",
    "homeMenu.security": "Security",

    "DashboardHeader.labelTips": "Let’s get creative with e-Com365 Go CRM micro-service to organize your content.",
    "Customer.pushBtn": "Push message",
    "Customer.pushPromoBtn": "Push promotion",
    "Customer.findBtnLabel": "Find",
    "Customer.chooseCompany": "Click the button to select company",
    "Customer.addCustomerTitle": "Create customer",
    "Customer.editCustomerTitle": "Edit customer",
    "Customer.detailCustomerTitle": "Detail customer",
    "Customer.selectCompanyTitle": "Select company",
    "Customer.editPushMessage": "Set push message",
    "Customer.pushModal.okBtn": "Send",
    "customer.confirmModalDelMsg": "Are you sure to delete {name} with {key} equal {val}?",
    "customer.selectCompanyWarning": "Please select company!",
    "customer.confirmModalPromoMsg": "Are you sure to push promotion message to these {val} users?",
    "customer.addFirstCustomerTitle": "Add first customer",
    "customer.addFirstCustomerLabel1": "Having a customer list helps to know your audience better.",
    "customer.addFirstCustomerLabel2": "Manage your customers in one place and target your audinece better.",
    "customer.addFirstCustomer": "Add customer",
    "customer.addFirstCustomerReadMore": "Read more",
    "createCustomer.backLabel": "Back",

    "detailCustomer.editInfoButton": "Edit information",
    "Attribute.createAttributeTitle": "Create attribute",
    "Attribute.editAttributeTitle": "Edit attribute",
    "Attribute.detailAttributeTitle": "Attribute detail",
    "Segment.addSegmentTitle": "Create segment",
    "Segment.detailSegmentTitle": "Detail segment",
    "Segment.editSegmentTitle": "Edit segment",
    "Segment.confirmModalDelMsg": "Are you sure to delete segment data with id equal {val}?",
    "Subscribe.createTitle": "Create subscriber",
    "Subscribe.editTitle": "Edit subscriber",
    "Subscribe.DetailTitle": "Detail subscriber",

    "campaigns.addNewCampaignLabel": "Create new campaigns",
    "campaigns.saveButton": "Save campaign",
    "campaigns.editCampaignLabel": "Edit the campaign",
    "campaigns.sendCampaignLabel": "Send campaign",
    "campaigns.addNewJourneyLabel": "Create new journey",
    "campaigns.saveJourneyButton": "Save journey",
    "campaigns.editJourneyLabel": "Edit the journey",
    "campaigns.sendJourneyLabel": "Send journey",

    "whatsappTemplate.addNewDataLabel": "Create new template",

    "Order.addOrderTitle": "Create order",
    "Order.detailOrderTitle": "Information",
    "Order.OrderInvoiceTitle": "Invoice",
    "Order.OrderShipmentTitle": "Shipment",
    "Order.editOrderTitle": "Edit order",
    "Order.addFirstOrderTitle": "No orders yet",
    "Order.addFirstOrderLabel1": "All the upcoming orders from your store will be visible in this page.",
    "Order.addFirstOrderLabel2": "You can add orders by yourself if you sell offline.",
    "Order.addFirstOrder": "Add order",

    "rewardPoints.rewardRules": "Reward rules",
    "rewardPoints.createRewardRuleTitle": "Create rule",
    "rewardPoints.editRewardRuleTitle": "Detail rule",
    "rewardRule.confirmModalDelMsg": "Are you sure to delete rule data with id equal {val}?",

    "Config.createTitle": "Create config",
    "Config.editTitle": "Edit config",

    "PageBuilder.detailPageTitle": "Detail page",
    "PageBuilder.addPageTitle": "Create page",
    "exports.exportButton": "Export",
    "exports.customerGrowth": "Customer growth",
    "exports.salesGoal": "Sales goal",
    "exports.conversionRate": "Conversion rate",
    "exports.storeFunnel": "Store funnel",
    "exports.ageDistribution": "Age distribution",
    "exports.averageOrderValue": "Average order value",
    "company.addCompanyTitle": "Create company",
    "company.editCompanyTitle": "Edit company",
    "company.confirmModalDelMsg": "Are you sure to delete company data with id equal {val}?",

    "Message.PushDetailTitle": "Push detail",
    "Message.PushTargetTitle": "Push target",
    "Message.notFoundDetail": "Message not found",
    "MessageModal.text_isRequired": "Please input a text or delete this field.",

    "pushModal.addTextBtn": "Add text",
    "pushModal.addImageBtn": "Upload image",
    "pushModal.addImagePlaceHolder": "Input the image link or upload the file(png/jpg/jpeg), multiple links are separated by [ , ]",
    "pushModal.addWeImagePlaceHolder": "Input the image link or upload the file(png/jpg), multiple links are separated by [ , ]",
    "pushModal.uploadImageTypeError": "{name} is not a valid image",
    "pushModal.addVideoBtn": "Upload video",
    "pushModal.addVideoPlaceHolder": "Input the video link or upload the file(mp4/avi/mov/flv), multiple links are separated by [ , ]",
    "pushModal.addWeVideoPlaceHolder": "Input the video link or upload the file(mp4), multiple links are separated by [ , ]",
    "pushModal.uploadVideoTypeError": "{name} is not a valid video",
    "pushModal.addDocumentBtn": "Upload document",
    "pushModal.addDocumentPlaceHolder": "Input the document link or upload the file(doc/docx/pdf/xls/xlsx/ppt/pptx), multiple links are separated by [ , ]",
    "pushModal.uploadDocumentTypeError": "{name} is not a valid document",
    "pushModal.uploadSize": "Max size cannot exceed 30mb",
    "pushModal.uploadFileTypeError": "{name} is not a valid file",

    "role.addRoleTitle": "Create role",
    "role.editRoleTitle": "Edit role",
    "role.confirmModalDelMsg": "Are you sure to delete role data with rolename equal {val}?",
    "user.addUserTitle": "Create user",
    "user.editUserTitle": "Edit user",
    "user.confirmModalDelMsg": "Are you sure to delete user data with uid equal {val}?",

    "merchantUsers.users": "Users",
    "merchantUsers.role": "Role",
    "merchantUsers.email": "Email",
    "merchantUsers.createdAt": "Account created",
    "merchantUsers.updatedAt": "Last modified",
    "merchantUsers.action": "Action",
    "merchantUsers.addUserTitle": "Create user",
    "merchantUsers.editUserTitle": "Edit user",
    "merchantUsers.addBtn": "Add one user",
    "merchantUsers.manageRolePermissionsBtn": "Manage role permissions",
    "merchantUsers.totalsSummary": "Showing {from} to {to} of {total} results",
    "merchantUsers.filterBtn": "Filter",
    "merchantUsers.first_name": "Firstname",
    "merchantUsers.last_name": "Lastname",
    "merchantUsers.role_id": "Role",
    "merchantUsers.merchant_id": "Merchant company",
    "merchantUsers.activeFilters": "Active filters:",
    "merchantUsers.merchantCompany": "Merchant company",
    "merchantUsers.deleteMerchantUser": "Delete merchant user",
    "merchantUsers.confirmModalDelMsg": "Are you sure to delete merchant user data with email equal {email}?",
    "merchantUsers.permissionTitle": "{username}'s permission",
    "merchantUser.permissons": "Permissons",

    "merchantsVendor.addBtn": "Create new service vendor",
    "merchantsVendor.filterBtn": "Filter",
    "merchantsVendor.activeFilters": "Active filters:",
    "merchantsVendor.addMerchantTitle": "Create service vendor",
    "merchantsVendor.editMerchantTitle": "Edit service vendor",
    "merchantsVendor.createdAt": "Instance created",
    "merchantsVendor.updatedAt": "Last modified",
    "merchantsVendor.action": "Action",
    "merchantsVendor.totalsSummary": "Showing {from} to {to} of {total} results",
    "merchantsVendor.active": "Active",
    "merchantsVendor.instanceId": "Instance ID",
    "merchantsVendor.serviceId": "Merchant service",
    "merchantsVendor.serviceStartDate": "Service start date",
    "merchantsVendor.serviceEndDate": "Service end date",
    "merchantsVendor.vendorId": "Vendor ID",
    "merchantsVendor.vendorCode": "Vendor code",
    "merchantsVendor.vendorName": "Vendor name",
    "merchantsVendor.vendorContactEmail": "Vendor contact email",
    "merchantsVendor.vendorContactPhone": "Vendor contact phone",
    "merchantsVendor.frontendApiGateway": "Frontend api gateway",
    "merchantsVendor.vendorMerchantId": "Vendor merchant",
    "merchantsVendor.merchantId": "Merchant",
    "merchantsVendor.vendor_merchant_id": "Vendor merchant",
    "merchantsVendor.merchant_id": "Merchant",
    "merchantsVendor.vendor_name": "Vendor name",
    "merchantsVendor.vendor_code": "Vendor code",
    "merchantsVendor.deleteMerchantServiceInstance": "Delete instance",
    "merchantsVendor.confirmModalDelMsg": "Are you sure to delete vendor data with vendor id equal {vendorid}?",

    "marketplaces.merchantCode": "Merchant code",
    "marketplaces.merchantCompany": "Merchant company",
    "marketplaces.frontendUrl": "Frontend url",
    "marketplaces.action": "Action",
    "marketplaces.login": "Login",

    "settings.settings": "Settings",
    "settings.security": "Security",
    "settings.busiessProfile": "Business profile",
    "settings.profile": "My profile",
    "settings.accounts": "Accounts",
    "setting.passwordNewAndOldSame": "The new password can't equal current password!",
    "setting.passwordNotMatch": "The new password that you entered do not match!",
    "settings.passwordChange": "Change password  success",
    "businessProfile.businessProfile": "Edit business profile",
    "businessProfile.merchantId": "Merchant ID",
    "businessProfile.company": "Company",
    "businessProfile.contactEmail": "Contact email",
    "businessProfile.contactPhone": "Contact phone",
    "businessProfile.frontendUrl": "Frontend url",
    "businessProfile.timeZone": "Time zone",
    "profile.editProfile": "Edit my profile",
    "profile.merchantId": "Merchant ID",
    "profile.userId": "User ID",
    "profile.save": "Save",
    "profile.firstName": "First name",
    "profile.lastName": "Last name",
    "profile.userEmail": "User email",
    "profile.saveProfileSuccess": "Save profile  success",

    "warrantyForm.formTitle": "Registration form management",
    "warrantyForm.formPreviewLabel": "Preview",
    "warrantyForm.formEditLabel": "Edit",
    "warrantyForm.categoryPlaceholder": "- Select product category -",
    "warrantyForm.seriesPlaceholder": "- Select product series -",
    "warrantyForm.skuPlaceholder": "- Model No.(SKU) -",
    "warrantyForm.uploadBtn": "Upload files",
    "warrantyForm.uploadSizeTips": "Files must be less than 5mb.",
    "warrantyForm.uploadTypeTips": "Allowed file types: png gif jpg jpeg pdf.",
    "warrantyForm.isEmail": "Please enter a valid email account",
    "merchantAccount.merchantCompany": "Merchant company",
    "merchantAccount.merchantCode": "Merchant code",
    "merchantAccount.timeZone": "Time zone",
    "merchantAccount.action": "Action",

    "inviteAccept.register": "Sign up",
    "inviteAccept.acceptInvitation": "Accept invitation",
    "inviteAccept.inviteAccept": "Invitation accepted success!",
    "inviteAccept.createSuccess": "Create account success!",
    "inviteAccept.AcceptionSuccess": "Accept success",

    "merchantSubscribes.filterBtn": "Filter",
    "merchantSubscribes.activeFilters": "Active filters:",
    "merchantSubscribes.serviceName": "Service name",
    "merchantSubscribes.planName": "Plan name",
    "merchantUser.serviceCode": "Service code",
    "merchantUser.isMarketplace": "Is market place",
    "merchantUser.planRange": "Plan range",
    "merchantSubscribes.planStartDate": "Start date",
    "merchantSubscribes.planEndDate": "End date",
    "merchantSubscribes.planExtendDate": "Extend date",

    "lead.leads": "Leads",
    "lead.addCreateTitle": "Create lead",
    "lead.addFirstrTitle": "Add first lead",
    "lead.addFirstLeadLabel1": "Having a lead list helps to know your audience better.",
    "lead.addFirstLeadLabel2": "Manage your leads in one place and target your audinece better.",
    "lead.addFirstLead": "Add lead",
    "lead.addLead": "Add lead",
    "lead.editLeadTitle": "Edit lead",
    "lead.detailLeadTitle": "Detail lead",
    "lead.confirmModalDelMsg": "Are you sure to delete lead data with id equal {val}?",
    "lead.addLeadReadMore": "Read more",
    "lead.addLeadTitle": "Create lead",
    "lead.content2": "Most important information about the lead.",
    "lead.personalInformation.leadUid": "Lead uid",
    "lead.personalInformation.leadCid": "Lead cid",

    "settings.Subscribes": "Service subscription",
    "settings.MerchantUsers": "Users",

    

}

export {
    en_US
}   