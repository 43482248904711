
import { post, get } from '../utils/http';

export function inviteCheck(param) {
    return post('admin_merchant/v1/invite/check', param, 'default');
}


export function register(param) {
    return post('admin_merchant/v1/invite/register', param, 'default');
}

export function accept(param) {
    return post('admin_merchant/v1/invite/accept', param, 'default');
}

